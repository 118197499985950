import adaptDecimalCricketData from './core__ws_cricket-decimal';

export default class CricketModel {
  constructor(data) {
    // DECIMAL cricket games
    if (data.providerRef === 'DECIMAL') return adaptDecimalCricketData(data);

    // SSOL cricket games (and maybe StatScore too?)
    const adaptedData = {
      data: {
        ...data,
        score: this.getCricketScore(data),
        game: this.getTeamScore(this.getCricketScore(data)),
      },
    };

    return adaptedData;
  }

  getCricketScore(data) {
    var cricketInnings = {};

    for (var i = 1; i <= 4; i++) {
      var order = i <= 2 ? 'First' : 'Second';
      var batting = data['batting' + i];
      var inningOvers = data['inning' + i + 'Overs'] || '';
      var inningRuns = data['inning' + i + 'Runs'] || '';
      var inningWickets = data['inning' + i + 'Wickets'] || '-';
      if (data['innings'] !== i) {
        inningOvers = '-';
      }
      var innings = (inningRuns + '/' + inningWickets).replace('/-', '');
      if (data.cricket) {
        if (batting && batting === 'A') {
          var teamOneInnings = {};
          teamOneInnings['teamOne' + order + 'Innings'] = innings;
          teamOneInnings['teamOne' + order + 'InningsOvers'] = inningOvers;

          cricketInnings = Object.assign({}, cricketInnings, teamOneInnings);
        } else if (batting && batting === 'B') {
          var teamTwoInnings = {};
          teamTwoInnings['teamTwo' + order + 'Innings'] = innings;
          teamTwoInnings['teamTwo' + order + 'InningsOvers'] = inningOvers;
          cricketInnings = Object.assign({}, cricketInnings, teamTwoInnings);
        }
      } else {
        if (batting && batting === data['batting1']) {
          var teamOneTestInnings = {};
          teamOneTestInnings['teamOne' + order + 'Innings'] = innings;
          teamOneTestInnings['teamOne' + order + 'InningsOvers'] = inningOvers;

          cricketInnings = Object.assign(
            {},
            cricketInnings,
            teamOneTestInnings
          );
        } else if (batting && batting === data['batting2']) {
          var teamTwoTestInnings = {};
          teamTwoTestInnings['teamTwo' + order + 'Innings'] = innings;
          teamTwoTestInnings['teamTwo' + order + 'InningsOvers'] = inningOvers;
          cricketInnings = Object.assign(
            {},
            cricketInnings,
            teamTwoTestInnings
          );
        }
      }
    }
    cricketInnings['currentInnings'] = data.innings;
    cricketInnings['isTestMatch'] = !data.cricket;
    return cricketInnings;
  }
  getTeamScore(data) {
    if (data) {
      const inningCounter =
        data.currentInnings === 1 ? 1 : data.currentInnings - 1;
      const innings = ['', 'First', 'Second', 'Third', 'Fourth'];
      const score = [];
      let showAnd = false;
      for (var i = 1; i <= inningCounter; i++) {
        const teamOneInnings = data['teamOne' + innings[i] + 'Innings'];
        const teamOneOvers =
          (data['teamOne' + innings[i] + 'InningsOvers'] !== '-' &&
            ' (' + data['teamOne' + innings[i] + 'InningsOvers'] + ') ') ||
          '';
        const teamTwoInnings = data['teamTwo' + innings[i] + 'Innings'];
        const teamTwoOvers =
          (data['teamTwo' + innings[i] + 'InningsOvers'] !== '-' &&
            ' (' + data['teamTwo' + innings[i] + 'InningsOvers'] + ') ') ||
          '';
        if (i > 1) {
          showAnd = true;
        }

        if (data.isTestMatch) {
          score.push({
            home:
              data['teamTwo' + innings[i] + 'InningsOvers'] &&
              (showAnd
                ? ' & ' + teamTwoInnings + teamTwoOvers
                : teamTwoInnings + teamTwoOvers),
            away:
              data['teamOne' + innings[i] + 'InningsOvers'] &&
              (showAnd
                ? ' & ' + teamOneInnings + teamOneOvers
                : teamOneInnings + teamOneOvers),
            index: i,
          });
        } else {
          score.push({
            home:
              data['teamOne' + innings[i] + 'InningsOvers'] &&
              (showAnd
                ? ' & ' + teamOneInnings + teamOneOvers
                : teamOneInnings + teamOneOvers),
            away:
              data['teamTwo' + innings[i] + 'InningsOvers'] &&
              (showAnd
                ? ' & ' + teamTwoInnings + teamTwoOvers
                : teamTwoInnings + teamTwoOvers),
            index: i,
          });
        }
      }

      if (data.isTestMatch) {
        return {
          score,
          home:
            (data['teamTwo' + innings[inningCounter] + 'InningsOvers'] &&
              data['teamTwo' + innings[inningCounter] + 'Innings'] +
                ' (' +
                data['teamTwo' + innings[inningCounter] + 'InningsOvers'] +
                ') ') ||
            '-',
          away:
            (data['teamOne' + innings[inningCounter] + 'InningsOvers'] &&
              data['teamOne' + innings[inningCounter] + 'Innings'] +
                ' (' +
                data['teamOne' + innings[inningCounter] + 'InningsOvers'] +
                ') ') ||
            '-',
        };
      } else {
        return {
          score,
          home:
            (data['teamOne' + innings[inningCounter] + 'InningsOvers'] &&
              data['teamOne' + innings[inningCounter] + 'Innings'] +
                ' (' +
                data['teamOne' + innings[inningCounter] + 'InningsOvers'] +
                ') ') ||
            '-',
          away:
            (data['teamTwo' + innings[inningCounter] + 'InningsOvers'] &&
              data['teamTwo' + innings[inningCounter] + 'Innings'] +
                ' (' +
                data['teamTwo' + innings[inningCounter] + 'InningsOvers'] +
                ') ') ||
            '-',
        };
      }
    }
  }
}
