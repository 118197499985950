export default class WSBadmintonModel {
  constructor(data) {
    return {
      data: {
        ...data,
        game: this.getHomeAwayScore(data),
        ...this.setSetAndSetScores(data),
      },
    };
  }

  getHomeAwayScore(data) {
    let badmintonHomeAwayScore = {};

    const awayscore = data.awayteamscore;
    const homescore = data.hometeamscore;

    if (typeof homescore !== 'undefined') {
      badmintonHomeAwayScore = {
        home: homescore,
        away: awayscore,
      };
    }

    return badmintonHomeAwayScore;
  }
  //UTIL for setGames
  splitScore(scoreString, i) {
    if (!scoreString) return { home: '', away: '', index: parseInt(i) };
    const score = scoreString;
    return { home: score[0], away: score[1], index: parseInt(i) };
  }
  //set Set counter and set all the setscores into object array
  setSetAndSetScores(data) {
    const dataKeys = Object.keys(data);
    let counter = 0;
    let games = [];
    dataKeys.forEach(key => {
      if (key.indexOf('gamescore') >= 0) {
        const gameCount = key.split('gamescore')[1][0];

        if (key[9] === gameCount) {
          games.push(
            this.splitScore(
              [
                data['gamescore' + gameCount + 'team1'],
                data['gamescore' + gameCount + 'team2'],
              ],
              gameCount
            )
          );
        }
        counter++;
      }
    });
    // we need to display atleast 5 SETSCORES even if they are not returned (it can go up to 10, we just need to force 5)

    if (games.length < 5) {
      for (let i = games.length; i < 5; i++) {
        games.push(this.splitScore(false, i - 1));
      }
    }

    games = games.sort((a, b) => a.index - b.index);
    return { games, counter };

    // return counter;
  }
}
