export default class SoccerModel {
  constructor(data) {
    return {
      data: {
        ...data,
        away: setAway(data),
        home: setHome(data),
        game: { home: data.homescore, away: data.awayscore },
        sourceKey: data.sourceKey,
        minute: data.minute,
        second: data.second,
        period: data.period, //1st, 2nd ...
        extratime: data.extratime,
        isPenalty: setPenalty(data),
      },
    };
  }
}

const setHome = data => {
  return {
    corners: data.homecorners,
    penalties: data.homepenalties,
    redCards: data.homeredcards,
    score: data.homescore,
    substitutions: data.homesubstitutions,
    woodwork: data.homewoodwork,
    yellowCards: data.homeyellowcards,
  };
};

const setAway = data => {
  return {
    corners: data.awaycorners,
    penalties: data.awaypenalties,
    score: data.awayscore,
    substitutions: data.awaysubstitutions,
    woodwork: data.awaywoodwork,
    yellowCards: data.awayyellowcards,
    redCards: data.awayredcards,
  };
};
const setPenalty = data => {
  const periodArray = data['period'] ? data['period'].split('-') : [];
  return periodArray.length > 1;
};
