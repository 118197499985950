export default class WSSnookerModel {
  constructor(data) {
    return {
      data: { ...data, game: this.getTeamScore(data) },
    };
  }

  getTeamScore(data, isAmericanSport) {
    var teamScore = {};

    const team1Score = data?.score.split(':')[0];
    const team2Score = data?.score.split(':')[1];

    if (typeof team1Score !== 'undefined') {
      if (isAmericanSport) {
        teamScore = {
          home: team2Score,
          away: team1Score,
        };
      } else {
        teamScore = {
          home: team1Score,
          away: team2Score,
        };
      }
    }

    return teamScore;
  }
}
