export default class WSMarketParser {
  constructor(data) {
    this.updatedMarket = this.createUpdate(data);

    const hasBooks = data.books && data.books.length;
    const hasSelections = data.selections && data.selections.length;

    if (hasBooks && hasSelections) this.processBookAndSelections(data);
    else if (hasBooks && !hasSelections) this.processBooks(data);
    else this.processSelections(data);

    return this.updatedMarket;
  }

  createUpdate(market) {
    return {
      updatedBook: false,
      updatedSelections: false,
      id: market.id,
      active: market.a,
      state: market.ms,
      name: market.n,
      sequence: market.seq || false,
    };
  }

  processBookAndSelections(data) {
    this.updatedMarket.selections = parseSelections(data.selections);
    this.updatedMarket.books = parseBooks(data.books);
    this.updatedMarket.updatedBook = true;
    this.updatedMarket.updatedSelections = true;
  }

  processBooks(data) {
    this.updatedMarket.books = parseBooks(data.books);
    this.updatedMarket.updatedBook = true;
  }

  processSelections(data) {
    this.updatedMarket.selections = parseSelections(data.selections);
    this.updatedMarket.updatedSelections = true;
  }
}

const parseSelections = selections => {
  return Object.keys(selections).map(i => {
    const selection = selections[i];
    return {
      id: parseInt(i),
      active: selection.a,
      name: selection.n || '',
      sourceKey: selection.sk || '',
      typeRef: selection.t || '',
      price: {
        id: selection.pid,
        bookId: selection.bid,
        active: selection.a,
        decimal: selection.v,
        fractional: selection.vf,
        bookType: 'PRICE',
      },
    };
  });
};

const parseBooks = books => {
  return Object.keys(books).map(key => {
    const selection = books[key];
    return {
      id: parseInt(key),
      antePost: selection.ap,
      open: selection.o,
      placeTerms: selection.pt,
      rule4Applicable: selection.r4,
      bookType: selection.t,
    };
  });
};
