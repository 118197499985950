export default class WSFutsalModel {
  constructor(data) {
    return {
      data: {
        ...data,
        game: this.getHomeAwayScore(data, true),
      },
    };
  }

  getHomeAwayScore(data, isAmericanSport) {
    let futsalHomeAwayScore = {};

    const awayscore = data.awayTeamScore;
    const homescore = data.homeTeamScore;

    if (typeof homescore !== 'undefined') {
      if (isAmericanSport) {
        futsalHomeAwayScore = {
          home: homescore,
          away: awayscore,
        };
      } else {
        futsalHomeAwayScore = {
          home: awayscore,
          away: homescore,
        };
      }
    }

    return futsalHomeAwayScore;
  }
}
