import { requiredPropertiesByCategory } from './utils/core__ws_event_requiredProperties';
import { WSEventParserModel } from './models/core__ws_eventParserModel';

class WSEventParser {
  constructor(data) {
    try {
      if (!data.sourceKey || !data.catRef) throw 'missing sourceKey or catRef';
      this.data = this.parse(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info(e);
    }
  }

  parse(data) {
    // check if we got all the information needed procceed
    if (!requiredPropertiesByCategory(data)) {
      throw `ws: missing required properties for ${data.catRef} (${data.providerRef})`;
    }

    // load the data model/parsing according to the category ref
    const ParserModel = WSEventParserModel[data.catRef.toLowerCase()];

    if (!ParserModel) throw 'ws: no data model';

    // create Category model
    const CategoryModel = new ParserModel(data);

    if (!CategoryModel.data) throw 'ws: no data parsed';

    return CategoryModel.data;
  }
}

export default WSEventParser;
