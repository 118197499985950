export default class WSGaelicFootballModel {
  constructor(data) {
    return {
      data: {
        ...data,
        game: { home: data.homegoals, away: data.awaygoals },
        awaypoints: this.getGaelicPoints(data, 'awaypoints'),
        homepoints: this.getGaelicPoints(data, 'homepoints'),
        ...this.getGaelicScore(data),
      },
    };
  }

  getGaelicPoints(data, pointsPropertyName) {
    var points = data[pointsPropertyName];
    var gaelicPoints = {};

    if (points) {
      var doubleDigitPoints = makeDoubleDigitNumber(points);

      gaelicPoints[pointsPropertyName] = doubleDigitPoints;
    }

    return gaelicPoints;
  }
  getGaelicScore(data) {
    const games = [];
    const homePoints = data.homepoints;
    const awayPoints = data.awaypoints;
    const homeGoals = data.homegoals;
    const awayGoals = data.awaygoals;

    games.push({ home: homePoints, away: awayPoints, index: 'Point' });
    games.push({ home: homeGoals, away: awayGoals, index: 'Goal' });

    return { games };
  }
}
function makeDoubleDigitNumber(digit) {
  return parseInt(digit, 10) < 10 ? '0' + digit : '' + digit;
}
