export default class WSBaseBallModel {
  constructor(data) {
    return {
      data: {
        ...data,
        game: this.getHomeAwayRuns(data),
        ...this.setSetAndSetScores(data, true),
      },
    };
  }

  getHomeAwayRuns(data, isAmericanSport) {
    var homeAwayRuns = {};
    const awayteamruns = data.awayteamruns;
    const hometeamruns = data.hometeamruns;

    if (typeof hometeamruns !== 'undefined') {
      if (isAmericanSport) {
        homeAwayRuns = {
          home: awayteamruns,
          away: hometeamruns,
        };
      } else {
        homeAwayRuns = {
          home: hometeamruns,
          away: awayteamruns,
        };
      }
    }

    return homeAwayRuns;
  }
  //UTIL for setGames
  splitScore(scoreString, i) {
    if (!scoreString) return { home: '', away: '', index: parseInt(i) };
    const score = scoreString;
    return { home: score[0], away: score[1], index: parseInt(i) };
  }
  //set Set counter and set all the setscores into object array
  setSetAndSetScores(data) {
    const dataKeys = Object.keys(data);
    let counter = 0;
    let games = [];
    dataKeys.forEach(key => {
      if (key.indexOf('Home') >= 0) {
        const inningsCount = key.match(/(\d+)/)[0];

        if (key.indexOf(inningsCount) > -1) {
          games.push(
            this.splitScore(
              [
                data['innings' + inningsCount + 'Home'],
                data['innings' + inningsCount + 'Away'],
              ],
              inningsCount
            )
          );
        }
        counter++;
      }
    });
    // we need to display atleast 5 SETSCORES even if they are not returned (it can go up to 10, we just need to force 5)
    if (games.length < 5) {
      for (let i = games.length; i < 5; i++) {
        games.push(this.splitScore(false, i + 1));
      }
    }

    games = games.sort((a, b) => a.index - b.index);
    return { games, counter };

    // return counter;
  }
}
