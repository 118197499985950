const formatCricketScore = (runs, wickets, overs, isCurrentInnings) => {
  // when runs, wickets and overs are all equal to zero, we are between innings
  if (runs === 0 && wickets === 0 && overs === '-') return '-';

  return [
    [runs, ...(wickets !== undefined ? [`/${wickets}`] : [])].join(''),
    ...(isCurrentInnings && overs ? [`(${overs})`] : []),
  ].join(' ');
};

const sanitiseInningsData = (runs, wickets, overs) => {
  if (runs === '' || runs === undefined) return undefined;

  return {
    runs: Number(runs),
    wickets:
      wickets !== undefined && wickets !== '' ? Number(wickets) : undefined,
    overs: Number(overs) || '-',
  };
};

const sanitiseAllInningsData = data => [
  sanitiseInningsData(data.inning1Runs, data.inning1Wickets, data.inning1Overs),
  sanitiseInningsData(data.inning2Runs, data.inning2Wickets, data.inning2Overs),
  sanitiseInningsData(data.inning3Runs, data.inning3Wickets, data.inning3Overs),
  sanitiseInningsData(data.inning4Runs, data.inning4Wickets, data.inning4Overs),
];

// this does support both Decimal and SSOL
const adaptDecimalCricketData = data => {
  const allInnings = sanitiseAllInningsData(data);
  const currentInnings = allInnings.reduce(
    (acc, inningsData) => (inningsData ? acc + 1 : acc),
    0
  );
  const isTeam1HomeDecimal = data.batting1 === data.home;
  const isTeam1HomeSSOL = data.batting1 === 'A';
  const isTeam1Home = isTeam1HomeDecimal || isTeam1HomeSSOL;

  const keyTeam1 = isTeam1Home ? 'home' : 'away';
  const keyTeam2 = isTeam1Home ? 'away' : 'home';

  const score = [
    {
      index: 1,
      [keyTeam1]: allInnings[0]
        ? formatCricketScore(
            allInnings[0].runs,
            allInnings[0].wickets,
            allInnings[0].overs,
            currentInnings === 1
          )
        : undefined,
      [keyTeam2]: allInnings[1]
        ? formatCricketScore(
            allInnings[1].runs,
            allInnings[1].wickets,
            allInnings[1].overs,
            currentInnings === 2
          )
        : undefined,
    },
    ...(allInnings[2]
      ? [
          {
            index: 2,
            [keyTeam1]: allInnings[2]
              ? formatCricketScore(
                  allInnings[2].runs,
                  allInnings[2].wickets,
                  allInnings[2].overs,
                  currentInnings === 3
                )
              : undefined,
            [keyTeam2]: allInnings[3]
              ? formatCricketScore(
                  allInnings[3].runs,
                  allInnings[3].wickets,
                  allInnings[3].overs,
                  currentInnings === 4
                )
              : undefined,
          },
        ]
      : []),
  ];

  return {
    data: {
      ...data,
      innings: currentInnings,
      game: { score },
    },
  };
};

export default adaptDecimalCricketData;
