export default class WSBasketBallModel {
  constructor(data) {
    return {
      data: {
        ...data,
        ...this.setSetAndSetScores(data),
        game: this.getTeamScore(data, true),
      },
    };
  }

  //UTIL for setGames
  splitScore(scoreString, i) {
    if (!scoreString) return { home: '', away: '', index: parseInt(i) };
    const score = scoreString;
    return { home: score[0], away: score[1], index: parseInt(i) };
  }

  //set Set counter and set all the setscores into object array
  setSetAndSetScores(data) {
    const dataKeys = Object.keys(data);
    let counter = 0;
    let games = [];
    dataKeys.forEach(key => {
      if (key.indexOf('score') >= 0) {
        const quarter = key.split('quarter')[1][0];
        const team = key.split('team')[1][0];

        if (key[12] === quarter && key[4] === team) {
          games.push(
            this.splitScore(
              [
                data['team' + 1 + 'quarter' + quarter + 'score'],
                data['team' + 2 + 'quarter' + quarter + 'score'],
              ],
              quarter
            )
          );
        }
        counter++;
      }
    });

    games = games.sort((a, b) => a.index - b.index);

    // we need to display atleast 5 SETSCORES even if they are not returned (it can go up to 10, we just need to force 5)

    const gamesLastIndex = games.at(-1)?.index;
    if (gamesLastIndex < 4) {
      for (let i = gamesLastIndex + 1; i < 5; i++) {
        games.push(this.splitScore(false, i));
      }
    }

    return { counter, games };

    // return counter;
  }

  getTeamScore(data, isAmericanSport) {
    var teamScore = {};

    const team1Score = data.team1Score;
    const team2Score = data.team2Score;

    if (typeof team1Score !== 'undefined') {
      if (isAmericanSport) {
        teamScore = {
          home: team1Score,
          away: team2Score,
        };
      } else {
        teamScore = {
          home: team2Score,
          away: team1Score,
        };
      }
    }

    return teamScore;
  }
}
