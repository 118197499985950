import WSMarketParser from './market/core__ws_marketParser';
import WSEventParser from './event/core__ws_eventParser';
import PubSub from '../pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';

class WSBroadCaster {
  constructor() {}

  static processEventUpdate(data) {
    const wsProcessed = new WSEventParser(data);
    if (!data.newMarketId) {
      if (wsProcessed && wsProcessed.data)
        PubSub.emit('ws:event', wsProcessed.data);
    } else {
      window.wslog &&
        window.wslog('processEventUpdate', 'ws:event', wsProcessed);
    }
  }

  static processMarketUpdate(ws) {
    if (ws.data && ws.data.type === GLOBAL_CONSTANTS.MARKET) {
      PubSub.emit('ws:market', new WSMarketParser(ws.data));
    }
  }

  static processBalanceUpdate(ws) {
    if (ws.data) {
      PubSub.emit(PubsubEvents.GET_UPDATE_BALANCE_FROM_WS, ws.data);
    }
  }
}
export default WSBroadCaster;
