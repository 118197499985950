export default class WSAmericanFootballModel {
  constructor(data) {
    return {
      data: {
        ...data,
        game: this.getHomeAwayScore(data),
        server: data.possession === 'HA' ? '1' : '2',
        ...this.setSetAndSetScores(data),
      },
    };
  }

  getHomeAwayScore(data) {
    let americanFotballHomeAwayScore = {};

    const awayscore = data.awayscore;
    const homescore = data.homescore;

    if (typeof homescore !== 'undefined') {
      americanFotballHomeAwayScore = {
        home: awayscore,
        away: homescore,
      };
    }

    return americanFotballHomeAwayScore;
  }
  //UTIL for setGames
  splitScore(scoreString, i) {
    if (!scoreString) return { home: '', away: '', index: parseInt(i) };
    const score = scoreString;
    return { home: score[0], away: score[1], index: parseInt(i) };
  }

  //set Set counter and set all the setscores into object array
  setSetAndSetScores(data) {
    const dataKeys = Object.keys(data);
    let counter = 0;
    let games = [];
    dataKeys.forEach(key => {
      if (key.indexOf('points') >= 1) {
        const quarter = key.split('quarter')[1][0];
        const team = key.split('team')[1][0];

        if (key[7] === quarter && key[12] === team) {
          games.push(
            this.splitScore(
              [
                data['quarter' + quarter + 'team' + 1 + 'points'],
                data['quarter' + quarter + 'team' + 2 + 'points'],
              ],
              quarter
            )
          );
        }
        counter++;
      }
    });
    // we need to display atleast 5 SETSCORES even if they are not returned (it can go up to 10, we just need to force 5)

    if (games.length < 5) {
      for (let i = games.length; i < 4; i++) {
        games.push(this.splitScore(false, i + 1));
      }
    }

    games = games.sort((a, b) => a.index - b.index);
    return { counter, games };
  }
}
