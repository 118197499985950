const REQUIRED_PROPERTIES_BY_SPORT = {
  american_football: ['homescore'],
  baseball: ['hometeamruns'],
  basketball: ['team2Score'],
  badminton: ['hometeamscore'],
  cricket: ['innings1', 'inning1Runs'],
  darts: ['legsplayer1'],
  gaelic_football: ['homepoints'],
  gaelic_hurling: ['homepoints'],
  ice_hockey: ['team2Score'],
  handball: ['team2Score'],
  rugby_league: ['homeTeamScore'],
  rugby_union: ['homeTeamScore'],
  snooker: ['score'],
  tennis: ['status'],
  soccer: ['minute', 'tickerState'],
  volleyball: ['setscore1team1'],
  table_tennis: ['status'],
  esports: ['gamescore'],
  mixedmartialarts: ['round'],
  boxing: ['round'],
  australian_rules: ['homescore'],
  futsal: ['awayTeamScore'],
};

const requiredPropertiesByCategory = data => {
  const requiredProperties =
    REQUIRED_PROPERTIES_BY_SPORT[data.catRef.toLowerCase()];

  return (
    requiredProperties &&
    requiredProperties.some(property => data[property] !== undefined)
  );
};

export { requiredPropertiesByCategory };
