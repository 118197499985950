export default class DartsModel {
  constructor(data) {
    return {
      data: {
        ...data,
        game: this.getDartsScore(data),
        ...this.getGameScore(data),
        server:
          data.playerwiththrow && data.playerwiththrow === 'A' ? '1' : '2',
        ...this.scoreSummary(data),
      },
    };
  }
  getDartsScore(data) {
    const homeAwayScore = {
      player180Score: { home: '', away: '' },
    };
    homeAwayScore.player180Score.home = data?.legsthissetplayer1;
    homeAwayScore.player180Score.away = data?.legsthissetplayer2;
    return homeAwayScore.player180Score;
  }
  getGameScore(data) {
    const games = [];
    const isSetGame = data.matchstatename.includes('sets');
    const legsplayer1 = data.legsplayer1;
    const legsplayer2 = data.legsplayer2;
    const currentSetLegsPlayer1 = data.legsthissetplayer1;
    const currentSetLegsPlayer2 = data.legsthissetplayer2;
    const setsplayer1 = data.setsplayer1;
    const setsplayer2 = data.setsplayer2;
    if (isSetGame) {
      games.push({ home: setsplayer1, away: setsplayer2, index: 'S' });
      games.push({
        home: currentSetLegsPlayer1,
        away: currentSetLegsPlayer2,
        index: 'L',
      });
    } else {
      games.push({ home: legsplayer1, away: legsplayer2, index: 'L' });
    }

    return { games };
  }
  scoreSummary(data) {
    const dartsData = {
      currentSetScore: '0/0',
      gameScore: '0/0',
    };
    dartsData['isSetAvailable'] = data.matchstatename.includes('sets');
    data.matchstatename.includes('sets')
      ? (dartsData['setNumber'] = data.matchstatename.match(/\d+/)[0])
      : '3';
    dartsData[
      'gameScore'
    ] = `${data.player180splayer1}:${data.player180splayer2}`;
    return { dartsData };
  }
}
