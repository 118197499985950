import cometd from 'cometd';
import WSSubscriber from './core__ws_subscriber';

class CometdService {
  constructor() {
    this.cometd = false;
    this.WSSubscriber = WSSubscriber;

    this.init();
    this.startCometD();
  }
  init() {
    function _setHeaders(xhr, headers) {
      if (headers) {
        for (const headerName in headers) {
          if (headerName.toLowerCase() === 'content-type') {
            continue;
          }
          xhr.setRequestHeader(headerName, headers[headerName]);
        }
      }
    }

    // Remap toolkit-specific transport calls
    function LongPollingTransport() {
      const _super = new cometd.LongPollingTransport();

      const that = cometd.Transport.derive(_super);

      that.xhrSend = function (packet) {
        var xhr = that.newXMLHttpRequest();
        xhr.context = that.context;

        xhr.withCredentials = true;
        xhr.open('POST', packet.url, packet.sync !== true);
        var headers = packet.headers;
        if (headers) {
          _setHeaders(xhr, packet);
        }
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = function () {
          if (xhr.status === 200) {
            packet.onSuccess(xhr.responseText);
          } else {
            packet.onError(xhr.statusText);
          }
        };
        xhr.onabort = xhr.onerror = function () {
          packet.onError(xhr.statusText);
        };
        xhr.send(packet.body);
        return xhr;
      };

      return that;
    }

    function CallbackPollingTransport() {
      const _super = new cometd.CallbackPollingTransport();
      const that = cometd.Transport.derive(_super);
      let jsonp = 0;
      that.jsonpSend = packet => {
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');

        var callbackName = '_cometd_jsonp_' + jsonp++;
        window[callbackName] = function (responseText) {
          head.removeChild(script);
          delete window[callbackName];
          packet.onSuccess(responseText);
        };

        var url = packet.url;
        url += url.indexOf('?') < 0 ? '?' : '&';
        url += 'jsonp=' + callbackName;
        url += '&message=' + encodeURIComponent(packet.body);
        script.src = url;
        script.async = packet.sync !== true;
        script.type = 'application/javascript';
        script.onerror = function (e) {
          packet.onError('jsonp ' + e.type);
        };
        head.appendChild(script);
      };
      return that;
    }

    const CometdInstance = name => {
      const _cometd = new cometd.CometD(name);

      if (window.WebSocket) {
        _cometd.registerTransport(
          'websocket',
          new cometd.WebSocketTransport(),
          0
        );
      }
      _cometd.unregisterTransport('long-polling'); //remove existing default long-polling
      _cometd.registerTransport('long-polling', LongPollingTransport(), 1);

      _cometd.unregisterTransport('callback-polling'); ////remove existing default callback-polling
      _cometd.registerTransport(
        'callback-polling',
        CallbackPollingTransport(),
        2
      );

      return _cometd;
    };

    this.cometd = CometdInstance('fsb');
  }

  handshakeConfirmation() {
    this.WSSubscriber.start(this.cometd);
  }

  startCometD() {
    if (this.cometd.addListener) {
      this.cometd.addListener(
        '/meta/handshake',
        this.handshakeConfirmation.bind(this)
      );
      this.cometd.configure({
        maxConnections: 2,
        url: location.protocol + '//' + location.host + '/cometd',
        logLevel: 'warn',
      });
      this.cometd.handshake();
      // Disconnect when the page unloads
      window.addEventListener('beforeunload', () => {
        this.cometd.disconnect(true);
      });
    }
  }
}
const singleton = new CometdService();
export default singleton;
