import { getOrdinals } from '../../../../sportsbook/components/EventsApp/core__events-utils';

class WSTennisModel {
  constructor(data) {
    this.data = this.parseData(data);
  }

  //parses all the data
  parseData(data) {
    return {
      ...data,
      playerScore: this.setScore(data),
      server: this.setServer(data),
      ...this.setSetAndSetScores(data), //set "counter" and "games"
      ...this.setGameScore(data), //set "game" from gameScore parsing
      ...this.scoreSummary(data),
    };
  }
  //set Set counter and set all the setscores into object array
  setSetAndSetScores(data) {
    const dataKeys = Object.keys(data);
    let counter = 0;
    let games = [];

    dataKeys.forEach(key => {
      if (key.indexOf('setscore') >= 0) {
        const keyIndex = key.split('setscore')[1];
        games.push(this.splitScore(data[key], keyIndex));
        counter++;
      }
    });
    // we need to display atleast 5 SETSCORES even if they are not returned (it can go up to 10, we just need to force 5)

    if (games.length < 5) {
      for (let i = games.length; i < 5; i++) {
        games.push(this.splitScore(false, i + 1));
      }
    }

    games = games.sort((a, b) => a.index - b.index);

    return { counter, games };

    // return counter;
  }
  //set players who is serving
  setServer(data) {
    return data['server'] && data['server'];
  }
  //set gameScore
  setGameScore(data) {
    const score = (data['gamescore'] || '0:0').split(':');
    const game = {
      home: score[0],
      away: score[1],
    };
    return { game };
  }
  //set global score
  setScore(data) {
    const score = (data['score'] || '0:0').split(':');
    const playerScore = {
      home: score[0],
      away: score[1],
    };
    return playerScore;
  }
  //UTIL for setGames
  splitScore(scoreString, i) {
    if (!scoreString) return { home: '', away: '', index: parseInt(i) };
    const score = scoreString.split(':');
    return { home: score[0], away: score[1], index: parseInt(i) };
  }
  scoreSummary(data) {
    const tennisData = {
      set: 'Set',
      currentSetScore: '0/0',
      gameScore: '0/0',
      server: '',
    };
    if (data) {
      const currentHomeSetScore = this.setSetAndSetScores(data).games.filter(
        score => score.index === this.setSetAndSetScores(data).counter
      )[0].home;
      const currentAwaySetScore = this.setSetAndSetScores(data).games.filter(
        score => score.index === this.setSetAndSetScores(data).counter
      )[0].away;
      const homeGameScore = data['gamescore'].split(':')[0];
      const awayGameScore = data['gamescore'].split(':')[1];
      tennisData['set'] =
        getOrdinals(this.setSetAndSetScores(data).counter) + ' Set';
      tennisData[
        'currentSetScore'
      ] = `${currentHomeSetScore}:${currentAwaySetScore}`;
      tennisData['gameScore'] = `${homeGameScore}:${awayGameScore}`;
      tennisData['server'] = data['server'];
    }
    return { tennisData };
  }
}

export default WSTennisModel;
