import SoccerModel from './core__ws_soccer';
import TableTennisModel from './core__ws_table_tennis';
import TennisModel from './core__ws_tennis';
import DartsModel from './core__ws_darts';
import VolleyballModel from './core__ws_volleyball';
import BasketBallModel from './core__ws_basketball';
import BaseBallModel from './core__ws_baseball';
import AmericanFootballModel from './core__ws_american_football';
import CricketModel from './core__ws_cricket';
import GaelicFootballModel from './core__ws_gaelic_football';
import RugbyLeagueModel from './core__ws_rugby_league';
import ESportsModel from './core__ws_esports';

import DefaultModel from './core__ws_default';
import WSSnookerModel from './core__ws_snooker';
import WSAustralianRulesModel from './core__ws_australian-rules';
import WSIceHockeyModel from './core__ws_iceHockey';
import WSFutsalModel from './core__ws_futsal';
import WSBadmintonModel from './core__ws_badminton';

const WSEventParserModel = {
  american_football: AmericanFootballModel,
  australian_rules: WSAustralianRulesModel,
  baseball: BaseBallModel,
  handball: BasketBallModel, // same as basket ball
  basketball: BasketBallModel,
  badminton: WSBadmintonModel,
  ice_hockey: WSIceHockeyModel,
  cricket: CricketModel,
  darts: DartsModel,
  gaelic_football: GaelicFootballModel,
  gaelic_hurling: GaelicFootballModel, //same as gaelic footbal
  rugby_league: RugbyLeagueModel,
  rugby_union: RugbyLeagueModel, //same as rugby league
  soccer: SoccerModel,
  table_tennis: TableTennisModel,
  tennis: TennisModel,
  snooker: WSSnookerModel,
  volleyball: VolleyballModel,
  esports: ESportsModel,
  boxing: DefaultModel,
  mixedmartialarts: DefaultModel,
  futsal: WSFutsalModel,
  default: DefaultModel,
};

export { WSEventParserModel };
