import { deleteRequest, getRequest } from '../http/core__axios';
import project from '../../project';
export default class SessionImpl {
  static customerUrl = '/fsb-api-rest/customer.fsb';
  static authenticateUrl = '/fsb-api-rest/authenticate.fsb';
  static cardUrl = '/fsb-api-rest/card.fsb';

  static async apiGetCustomerSession(accessToken) {
    if (!accessToken) return;

    let params = `?access_token=${accessToken}&productGroupRef=SPORTSBOOK,CASINO&details=true&metadata=true&platformConfig=true`;
    // NOTE: lastBets is a number
    const { customerSessionParam, addressRequiredPaths, lastBets } = project;

    try {
      if (
        addressRequiredPaths &&
        addressRequiredPaths.indexOf(window.location.pathname) !== -1
      ) {
        params += `&address=true`;
      }
      if (lastBets) {
        params += `&lastBets=${lastBets}`;
      }
    } catch (error) {
      //no handling required
    }
    if (customerSessionParam && Object.keys(customerSessionParam).length > 0) {
      Object.keys(customerSessionParam).map(
        itm => (params = params + '&' + itm + '=' + customerSessionParam[itm])
      );
    }
    return getRequest(`${this.customerUrl}${params}`);
  }

  static async apiDeleteCustomerSession(accessToken) {
    const params = `?access_token=${accessToken}`;
    if (!accessToken) return;
    return deleteRequest(`${this.authenticateUrl}${params}`);
  }
  static async apiGetCustomerCard(accessToken) {
    const params = `?access_token=${accessToken}`;
    if (!accessToken) return;
    return getRequest(`${this.cardUrl}${params}`);
  }
}
