import PubSub from '../pubsub/core__pubsub';
import SessionImpl from './core__session.impl';
import { processQsMetadata } from './core__session.utils';
import FSBCustomerSession from './models/core__session.models.fsb';
import { createCookie } from 'Services/cookie/core__cookies';
import Constants from '../../project';
import project from '../../project';
import Authenticators from './models/core__authenticators';

//OUTGOING EVENTS
const SESSION_CREATED = 'session:created';
const SESSION_UPDATED = 'session:updated';
const SESSION_DESTROYED = 'session:destroyed';

//INCOMING EVENTS
const SESSION_CREATE = 'session:new';
const SESSION_UPDATE = 'session:update';
const SESSION_DESTROY = 'session:destroy';
const SESSION_NOSESSION = 'session:nosession';
//CARDS DATA
const CUSTOMER_CARD = 'customer:card';
const SESSION_IFRAME_FAILED = 'SESSION_IFRAME_FAILED';

class SessionManager {
  constructor() {
    this.listeners();
    this.start();
  }

  listeners() {
    PubSub.listen(SESSION_CREATE, customerData =>
      this.newSession(customerData)
    );

    PubSub.listen(SESSION_UPDATE, () => this.updateSession());

    PubSub.listen(SESSION_DESTROY, () => this.destroySession());
  }

  async start() {
    if (project.pamAuthenticator) {
      const authenticate = Authenticators[project.pamAuthenticator];

      if (!authenticate) {
        return;
      }

      let previousSession = false;

      authenticate()
        .then(async customer => {
          // /gamesys/authenticate is called
          if (customer.inError) {
            if (
              customer?.response?.status === 401 ||
              customer?.response?.status === 500 ||
              customer?.response?.status === 400
            ) {
              this.destroySession();
              PubSub.emit(SESSION_IFRAME_FAILED, true);
            } else {
              this.destroySession();
              PubSub.emit(SESSION_IFRAME_FAILED, true);
            }
          } else if (customer.accessToken) {
            FSBCustomerSession.createCookieFromAuthenticate(customer);
            // /gamesys/authenticate is 200 - has customer data
            // const customerAPI = await SessionImpl.apiGetCustomerSession(customer.accessToken);

            SessionImpl.apiGetCustomerSession(customer.accessToken)
              .then(customerAPI => {
                if (customerAPI.inError) {
                  if (
                    customerAPI?.response?.status === 401 ||
                    customerAPI?.response?.status === 500
                  ) {
                    PubSub.emit(SESSION_IFRAME_FAILED, true);
                  }
                } else if (customerAPI.customer) {
                  FSBCustomerSession.updateSession(customerAPI.customer);
                  FSBCustomerSession.createCookie();
                  setTimeout(() => {
                    PubSub.emit(
                      SESSION_UPDATED,
                      FSBCustomerSession.getSession()
                    );
                    PubSub.emitBehavior(
                      SESSION_UPDATED,
                      FSBCustomerSession.getSession()
                    );
                  }, 600);
                }
              })
              .catch(err => {
                if (
                  err?.response?.status === 401 ||
                  err?.response?.status === 500
                ) {
                  PubSub.emit(SESSION_IFRAME_FAILED, true);
                }
              });
          } else {
            // current gamesys token was used on URL was consumed previously, and it is the same as we currently got cached on a cookie
            // tries to use the existent FSB customer session
            const oldSession = FSBCustomerSession.hasPreviousSession();

            if (oldSession) {
              SessionImpl.apiGetCustomerSession(oldSession)
                .then(customerAPI => {
                  if (customerAPI.inError) {
                    if (
                      customerAPI?.response?.status === 401 ||
                      customerAPI?.response?.status === 500
                    ) {
                      PubSub.emit(SESSION_IFRAME_FAILED, true);
                    }
                  } else if (customerAPI.customer) {
                    previousSession = true;
                    FSBCustomerSession.updateSession(customerAPI.customer);
                    FSBCustomerSession.createCookie();
                    setTimeout(() => {
                      PubSub.emit(
                        SESSION_UPDATED,
                        FSBCustomerSession.getSession()
                      );
                      PubSub.emitBehavior(
                        SESSION_UPDATED,
                        FSBCustomerSession.getSession()
                      );
                    }, 2000);
                  }
                })
                .catch(err => {
                  if (
                    err?.response?.status === 401 ||
                    err?.response?.status === 500
                  ) {
                    PubSub.emit(SESSION_IFRAME_FAILED, true);
                  }
                });
            }
          }
        })
        .catch(err => {
          if (!previousSession) {
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 500 ||
              err?.response?.status === 400
            ) {
              this.destroySession();
              PubSub.emit(SESSION_IFRAME_FAILED, true);
            } else {
              this.destroySession();
              PubSub.emit(SESSION_IFRAME_FAILED, true);
            }
          }
        });
    } else {
      this.startFSBPam();
    }
  }

  async startFSBPam() {
    let hasPreviousSession;
    const getIosToken = this.getSessionTokenFromUrl();
    if (getIosToken) {
      hasPreviousSession = getIosToken;
    } else {
      hasPreviousSession = FSBCustomerSession.hasPreviousSession();
    }
    this.setAffiliateTracking();
    this.setMetadataCookie();
    if (!hasPreviousSession) {
      PubSub.emit(SESSION_NOSESSION, false);
      return;
    }

    try {
      if (
        project?.preventSessionLogoutIniFrame &&
        !window.navigator.userAgent.includes('app=fsb-')
      ) {
        if (window.location === window.parent.location) {
          const customerAPI = await SessionImpl.apiGetCustomerSession(
            hasPreviousSession
          );
          //ROAD BLOCK IF STATUS !== ACTIVE
          const { accountStatus, accessToken } = customerAPI.customer;

          /* TODO - onshore work pending */
          // eslint-disable-next-line no-empty
          if (accountStatus !== 'ACTIVE') {
          }
          FSBCustomerSession.updateSession(customerAPI.customer);
          FSBCustomerSession.createCookie();
          if (customerAPI && accessToken && !Constants.cardAPICallRestriction) {
            const cardAPI = await SessionImpl.apiGetCustomerCard(accessToken);
            if (cardAPI && cardAPI?.customer) {
              PubSub.emit(CUSTOMER_CARD, cardAPI?.customer?.card || []);
            }
          }

          PubSub.emit(SESSION_UPDATED, FSBCustomerSession.getSession());
          PubSub.emitBehavior(SESSION_UPDATED, FSBCustomerSession.getSession());
        }
      } else {
        const customerAPI = await SessionImpl.apiGetCustomerSession(
          hasPreviousSession
        );
        //ROAD BLOCK IF STATUS !== ACTIVE
        const { accountStatus, accessToken } = customerAPI.customer;

        /* TODO - onshore work pending */
        // eslint-disable-next-line no-empty
        if (accountStatus !== 'ACTIVE') {
        }
        FSBCustomerSession.updateSession(customerAPI.customer);
        FSBCustomerSession.createCookie();
        if (customerAPI && accessToken && !Constants.cardAPICallRestriction) {
          const cardAPI = await SessionImpl.apiGetCustomerCard(accessToken);
          if (cardAPI && cardAPI?.customer) {
            PubSub.emit(CUSTOMER_CARD, cardAPI?.customer?.card || []);
          }
        }

        PubSub.emit(SESSION_UPDATED, FSBCustomerSession.getSession());
        PubSub.emitBehavior(SESSION_UPDATED, FSBCustomerSession.getSession());
      }
    } catch (e) {
      this.destroySession();
    }
  }

  async newSession(customerData) {
    try {
      //ROAD BLOCK IF STATUS !== ACTIVE
      const { accessToken, accountStatus } = customerData;
      /* Onshore TODO */
      // eslint-disable-next-line no-empty
      if (accountStatus !== 'ACTIVE') {
      }

      const customerAPI = await SessionImpl.apiGetCustomerSession(accessToken);

      FSBCustomerSession.updateSession(customerAPI.customer);
      FSBCustomerSession.createCookie();
      PubSub.emit(SESSION_CREATED, FSBCustomerSession.getSession());
      PubSub.emitBehavior(SESSION_CREATED, FSBCustomerSession.getSession());
      if (
        customerAPI &&
        customerAPI?.customer?.accessToken &&
        !Constants.cardAPICallRestriction
      ) {
        const cardAPI = await SessionImpl.apiGetCustomerCard(
          customerAPI.customer.accessToken
        );
        if (cardAPI && cardAPI?.customer) {
          PubSub.emit(CUSTOMER_CARD, cardAPI?.customer?.card || []);
          if (cardAPI && cardAPI?.customer) {
            PubSub.emit(CUSTOMER_CARD, cardAPI?.customer?.card || []);
          }
        }
      }
    } catch (e) {
      this.destroySession();
    }
  }

  async updateSession() {
    const fsbCustomerPreviousSession = FSBCustomerSession.getSession();

    /* Onshore TODO */
    // eslint-disable-next-line no-empty
    if (!fsbCustomerPreviousSession) {
    }

    try {
      const customerAPI = await SessionImpl.apiGetCustomerSession(
        fsbCustomerPreviousSession.accessToken
      );

      //ROAD BLOCK IF STATUS !== ACTIVE
      const { accountStatus } = customerAPI.customer;
      /* Onshore TODO */
      // eslint-disable-next-line no-empty
      if (accountStatus !== 'ACTIVE') {
      }
      FSBCustomerSession.updateSession(customerAPI.customer);
      FSBCustomerSession.createCookie();

      PubSub.emit(SESSION_UPDATED, FSBCustomerSession.getSession());
      PubSub.emitBehavior(SESSION_UPDATED, FSBCustomerSession.getSession());
      if (
        customerAPI &&
        customerAPI?.customer?.accessToken &&
        !Constants.cardAPICallRestriction
      ) {
        const cardAPI = await SessionImpl.apiGetCustomerCard(
          customerAPI.customer.accessToken
        );
        if (cardAPI && cardAPI?.customer) {
          PubSub.emit(CUSTOMER_CARD, cardAPI?.customer?.card || []);
        }
      }
    } catch (e) {
      this.destroySession();
    }
  }

  async destroySession() {
    const fsbCustomerPreviousSession = FSBCustomerSession.getSession();

    if (!fsbCustomerPreviousSession) {
      //still try to clear leftover data if its the case
      FSBCustomerSession.deleteSession();
      PubSub.emit(SESSION_DESTROYED);
      return;
    }

    try {
      await SessionImpl.apiDeleteCustomerSession(
        fsbCustomerPreviousSession.accessToken
      );

      FSBCustomerSession.deleteSession();

      PubSub.emit(SESSION_DESTROYED);
    } catch (e) {
      FSBCustomerSession.deleteSession();
      PubSub.emit(SESSION_DESTROYED);
    }
  }
  setAffiliateTracking() {
    const rawQuery = window.location.search.substring(1);
    let queryParams = rawQuery.split('&');
    queryParams = queryParams.reduce((params, param) => {
      const [key, value] = param.split('=');
      return { ...params, [key]: value };
    }, {});
    let affiliateTrack = false;
    if (queryParams.btag) {
      affiliateTrack = {
        affiliateName: 'btag',
        affiliateValue: queryParams.btag,
      };
    } else if (Constants.utmBtagTracking && rawQuery.indexOf('utm') >= 0) {
      affiliateTrack = {
        affiliateName: 'utm',
        affiliateValue: rawQuery.split('&'),
      };
    }
    if (affiliateTrack) {
      const cookieOptions = project?.affiliateTrackingCookieSettings || {};
      const value = JSON.stringify(affiliateTrack);
      createCookie('affiliateTrack', value, null, cookieOptions);
    }
  }
  getSessionTokenFromUrl() {
    const currenturlparameters = window.location.search;
    if (currenturlparameters.includes('iosToken')) {
      const token = this.getUrlVars()['iosToken'];
      const accessToken = decodeURIComponent(token);
      if (accessToken && accessToken !== '') {
        return accessToken;
      }
    }
    return false;
  }
  getUrlVars() {
    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (
      m,
      key,
      value
    ) {
      vars[key] = value;
    });
    return vars;
  }

  setMetadataCookie() {
    const url = window.location.href;
    const metadataArr = processQsMetadata(url);
    if (metadataArr.length > 0) {
      createCookie('metadata', JSON.stringify(metadataArr), 30);
    }
  }
}

const singleton = new SessionManager();
export default singleton;
