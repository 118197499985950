import { getOrdinals } from '../../../../sportsbook/components/EventsApp/core__events-utils';

class WSVolleyballModel {
  constructor(data) {
    this.data = this.parseData(data);
  }

  //parses all the data
  parseData(data) {
    return {
      ...data,
      playerScore: this.setScore(data),
      server: this.setServer(data),
      ...this.setSetAndSetScores(data),
      ...this.setGameScore(data), //set "game" from gameScore parsing
      ...this.scoreSummary(data),
    };
  }

  //set gameScore
  setGameScore(data) {
    var homeGameScore = 0;
    var awayGameScore = 0;

    for (var set = 1; set <= 5; set++) {
      var homeSetScoreName = 'setscore' + set + 'team1';
      var homeSetScore = data[homeSetScoreName];
      var awaySetScoreName = 'setscore' + set + 'team2';
      var awaySetScore = data[awaySetScoreName];

      if (
        homeSetScore &&
        awaySetScore &&
        (homeSetScore >= 25 || awaySetScore >= 25) &&
        Math.abs(homeSetScore - awaySetScore) > 1
      ) {
        homeSetScore > awaySetScore
          ? (homeGameScore += 1)
          : (awayGameScore += 1);
      }
    }
    return {
      game: { home: homeGameScore, away: awayGameScore },
      score: `${homeGameScore}:${awayGameScore}`,
    };
  }
  //set global score
  setScore(data) {
    const playerScore = {
      home: data.hometeamscore,
      away: data.awayteamscore,
    };
    return playerScore;
  }
  //set players who is serving
  setServer(data) {
    return data['server'] && data['server'];
  }
  //UTIL for setGames
  splitScore(scoreString, i) {
    if (!scoreString) return { home: '', away: '', index: parseInt(i) };
    const score = scoreString;
    return { home: score[0], away: score[1], index: parseInt(i) };
  }

  //set Set counter and set all the setscores into object array
  setSetAndSetScores(data) {
    const dataKeys = Object.keys(data);
    let counter = 0;
    let games = [];
    const setValues = [];
    dataKeys.forEach(key => {
      if (key.indexOf('setscore') >= 0) {
        const set = key.split('setscore')[1][0];
        const team = key.split('team')[1];
        const dynamicKey = 'setscore' + set + 'team';
        if (key[8] === set && key.slice(-1) === team) {
          setValues.indexOf(set) === -1 &&
            games.push(
              this.splitScore(
                [data[dynamicKey + '1'], data[dynamicKey + '2']],
                set
              )
            );
        }
        if (setValues.indexOf(set) === -1) {
          counter++;
        }
        setValues.push(set);
      }
    });
    // we need to display atleast 5 SETSCORES even if they are not returned (it can go up to 10, we just need to force 5)

    if (games.length < 5) {
      for (let i = games.length; i < 5; i++) {
        games.push(this.splitScore(false, i + 1));
      }
    }
    games = games.sort((a, b) => a.index - b.index);
    return { counter, games };
  }

  scoreSummary(data) {
    const scoreValues = this.setSetAndSetScores(data);
    const volleyballData = {
      set: 'Set',
      currentSetScore: '0/0',
      gameScore: '0/0',
      server: '',
    };
    volleyballData['set'] = getOrdinals(scoreValues.counter) + ' Set';
    volleyballData['currentSetScore'] = `${
      scoreValues.games[scoreValues.counter - 1].home
    }:${scoreValues.games[scoreValues.counter - 1].away}`;
    volleyballData['gameScore'] = `${data.hometeamscore}:${data.awayteamscore}`;
    volleyballData['server'] = data['server'];
    return { volleyballData };
  }
}

export default WSVolleyballModel;
