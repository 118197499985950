class WSESportsModel {
  constructor(data) {
    this.data = this.parseData(data);
  }

  //parses all the data
  parseData(data) {
    return {
      ...data,
      ...this.setGameScore(data), //set "game" from gameScore parsing
      ...this.getHomeAwayScore(data),
    };
  }
  setGameScore(data) {
    const score = (data['gamescore'] || '0:0').split(':');
    const game = {
      home: score[0],
      away: score[1],
    };
    return { game };
  }

  getHomeAwayScore(data) {
    const games = [];
    const periodScore1 = data['periodscore1'] || '0:0';
    const periodScore2 = data['periodscore2'] || '0:0';
    const periodScore3 = data['periodscore3'] || '0:0';
    games.push(
      {
        home: periodScore1.split(':')[0],
        away: periodScore1.split(':')[1],
        index: 1,
      },
      {
        home: periodScore2.split(':')[0],
        away: periodScore2.split(':')[1],
        index: 2,
      },
      {
        home: periodScore3.split(':')[0],
        away: periodScore3.split(':')[1],
        index: 3,
      }
    );
    return { games };
  }
}

export default WSESportsModel;
