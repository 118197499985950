import { getCookie, createCookie } from 'Services/cookie/core__cookies';
import project from '../../../project';
import axios from 'axios';

const generateUUID = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};
function postRequest(url, data = {}, options) {
  const dataObject =
    (data &&
      Object.keys(data)
        .map(key => `${key}=${data[key]}`)
        .join('&')) ||
    '';

  let headers = {};
  if (options?.headers) {
    headers = options.headers;
  }
  return axios
    .post(url, dataObject, {
      headers,
    })
    .then(res => {
      const respon = res;
      return respon.data.response;
    })
    .catch(err => {
      return {
        response: err?.response?.data.response,
        statusCode: err?.response?.status,
        statusText: err?.response?.statusText,
        inError: true,
      };
    });
}

const obj = {
  gamesys: async () => {
    return new Promise((resolve, reject) => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const cookieToken = getCookie('authenticationToken');

      if (
        urlParams.get('token') !== null &&
        cookieToken !== urlParams.get('token')
      ) {
        const reqObject = {
          language: urlParams.get('language'),
          clientId: project.clientId,
          clientSecret: project.clientSecret,
          token: urlParams.get('token'),
          uuid: generateUUID(),
        };

        return postRequest(`/fsb-api-rest/gamesys/authenticate`, reqObject)
          .then(res => {
            if (res.inError) {
              reject(res);
            } else {
              if (res && res?.customer && res?.customer?.accessToken) {
                createCookie('authenticationToken', urlParams.get('token'));
                resolve(res.customer);
              }
            }
          })
          .catch(res => reject(res));
      } else {
        resolve({});
      }
    });
  },
};

export default obj;
