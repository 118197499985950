import { createCookie, getCookie } from './cookie/core__cookies';
import { generateUUID } from './global/core__generate-uuid';

const setCookiesFromUrl = () => {
  const accessToken = new URLSearchParams(window.location.search).get('token');
  if (accessToken && window.location.href.indexOf('ios') > -1) {
    createCookie('AppSession', accessToken);
  }

  // store bsource in cookies
  const bSource = new URLSearchParams(window.location.search).get('bsource');
  if (bSource) {
    createCookie('bsource', bSource);
  }
};

//storing UUID in cookies if clid not available
const setInitialUUID = () => {
  if (!getCookie('clid')) {
    const uuid = generateUUID();
    createCookie('clid', uuid);
  }
};

setCookiesFromUrl();

setInitialUUID();
