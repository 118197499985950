import { getOrdinals } from '../../../../sportsbook/components/EventsApp/core__events-utils';

class WSTableTennisModel {
  constructor(data) {
    this.data = this.parseData(data);
  }

  //parses all the data
  parseData(data) {
    return {
      ...data,
      catRef: data.catRef,
      game: this.setScores(data),
      server: this.setServer(data),
      games: this.setGames(data),
      setSetAndSetScores: this.setSetAndSetScores(data),
      scoreSummary: this.scoreSummary(data),
    };
  }
  //create global scores (number of sets won)
  setScores(data) {
    const currentScore = (data['score'] || '0:0').split(':');

    return { home: currentScore[0], away: currentScore[1] };
  }
  //create server since it can be undefined at the begining of the game
  setServer(data) {
    return data.server && data.server;
  }
  //create set games scores
  setGames(data) {
    const games = [];

    Object.keys(data).forEach(key => {
      if (key.indexOf('setscore') >= 0) {
        const keyIndex = key.split('setscore')[1];
        games.push(this.splitScore(data[key], keyIndex));
      }
      if (key.indexOf('gamescore') >= 0) {
        const keyIndex = key.split('gamescore')[1];
        games.push(this.splitScore(data[key], keyIndex));
      }
    });

    // we need to display atleast 5 SETSCORES even if they are not returned (it can go up to 10, we just need to force 5)

    if (games.length < 5) {
      for (let i = games.length; i < 5; i++) {
        games.push(this.splitScore(false, i + 1));
      }
    }

    games.sort((a, b) => a.index - b.index);
    return games;
  }
  //set Set counter and set all the setscores into object array
  setSetAndSetScores(data) {
    const dataKeys = Object.keys(data);
    let counter = 0;
    let games = [];

    dataKeys.forEach(key => {
      if (key.indexOf('gamescore') >= 0) {
        const keyIndex = key.split('gamescore')[1];
        games.push(this.splitScore(data[key], keyIndex));
        counter++;
      }
    });
    // we need to display atleast 5 SETSCORES even if they are not returned (it can go up to 10, we just need to force 5)

    if (games.length < 5) {
      for (let i = games.length; i < 5; i++) {
        games.push(this.splitScore(false, i + 1));
      }
    }

    games = games.sort((a, b) => a.index - b.index);
    return { counter, games };

    // return counter;
  }

  //UTIL for setGames
  splitScore(scoreString, i) {
    if (!scoreString) return { home: '', away: '', index: parseInt(i) };
    const score = scoreString.split(':');
    return { home: score[0], away: score[1], index: parseInt(i) };
  }
  scoreSummary(data) {
    const tableTennisData = {
      set: 'Set',
      currentSetScore: '0/0',
      gameScore: '0/0',
      server: '',
    };
    if (data) {
      const currentHomeSetScore = this.setSetAndSetScores(data).games.filter(
        score => score.index === this.setSetAndSetScores(data).counter
      );
      const specificCurrentHomeSetScore = currentHomeSetScore[0].home;

      const currentAwaySetScore = this.setSetAndSetScores(data).games.filter(
        score => score.index === this.setSetAndSetScores(data).counter
      );
      const specificCurrentAwaySetScore = currentAwaySetScore[0].away;

      const homeGameScore = data['score'].split(':')[0];
      const awayGameScore = data['score'].split(':')[1];
      tableTennisData['set'] =
        getOrdinals(this.setSetAndSetScores(data).counter) + ' Set';
      tableTennisData[
        'currentSetScore'
      ] = `${specificCurrentHomeSetScore}:${specificCurrentAwaySetScore}`;
      tableTennisData['gameScore'] = `${homeGameScore}:${awayGameScore}`;
      tableTennisData['server'] = data['server'];
    }
    return { tableTennisData };
  }
}

export default WSTableTennisModel;
