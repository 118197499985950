export default class WSIceHockeyModel {
  constructor(data) {
    return {
      data: { ...data, game: this.getTeamScore(data, true) },
    };
  }
  getTeamScore(data, isAmericanSport) {
    var teamScore = {};

    const team1Score = data.team1Score;
    const team2Score = data.team2Score;

    if (typeof team1Score !== 'undefined') {
      if (isAmericanSport) {
        teamScore = {
          home: team1Score,
          away: team2Score,
        };
      } else {
        teamScore = {
          home: team2Score,
          away: team1Score,
        };
      }
    }

    return teamScore;
  }
}
